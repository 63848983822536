import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useLogger from '../../../../store/hooks/useLogger';
import useSite from '../../../../store/hooks/useSite';
import useMeter from '../../../../store/hooks/useMeter';
import useDeviceAlarms from '../../../../store/hooks/useDeviceAlarms';
import ProductionSums from './ProductionSums';
import MeterInfo from './MeterInfo';
import MeterChart from '../../../../components/charts/dashboard/MeterChart';
import AlarmsTable from '../../../../components/table/tables/AlarmsTable';
import ViewStack from '../../../../components/ViewStack';
import LatestData from './LatestData';

const TREND = 'trend';
const ALARMS = 'alarms';
const LATEST = 'latest';
const VIEWS = [TREND, ALARMS, LATEST];

function MeterDashboard() {
  const { id } = useSelector((state) => state.pages.meter);
  const meter = useMeter(id);
  const site = useSite(meter.site_id);
  const logger = useLogger(meter.logger_id);
  const alarms = useDeviceAlarms(id);
  const [selectedView, setSelectedView] = useState(TREND);

  if (!logger.active) {
    return (
      <Box sx={{ my: 4, width: '100%' }}>
        <Typography align='center' color='text.secondary'>
          Meter Inactive
        </Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={1}
      mt='0 !important'
      px={1}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} md={4}>
        <MeterInfo logger={logger} meter={meter} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProductionSums logger={logger} meter={meter} />
      </Grid>
      <Grid item xs={12}>
        <ViewStack
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          views={VIEWS}
        />
      </Grid>
      {selectedView === TREND && (
        <Grid item xs={12}>
          <Card raised sx={{ p: 1 }}>
            <MeterChart site={site} meter={meter} />
          </Card>
        </Grid>
      )}
      {selectedView === ALARMS && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <AlarmsTable resource={meter} alarms={alarms} />
        </Grid>
      )}
      {selectedView === LATEST && <LatestData />}
    </Grid>
  );
}

export default MeterDashboard;
