import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DialogTitleOptions from '../../Dialogs/DialogTitleOptions';

function SelectMonthRange(props) {
  const { range, setRange } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectDay = (day, attr) => {
    setRange({
      ...range,
      [attr]: day,
    });
  };

  let rangeLabel = `${dayjs(range.start).format('YYYY MMM')} - ${dayjs(
    range.end
  ).format('YYYY MMM')}`;

  return (
    <>
      <Button
        color='primary'
        onClick={() => setOpen(true)}
        sx={{ width: '10rem' }}>
        {rangeLabel}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle>
          Select Date Range
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2}>
            <DatePicker
              disableFuture
              views={['year', 'month']}
              label='Start'
              value={range.start}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'start');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
            <DatePicker
              disableFuture
              views={['year', 'month']}
              label='End'
              value={range.end}
              minDate={range.start}
              onChange={(newValue) => {
                handleSelectDay(newValue, 'end');
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

SelectMonthRange.propTypes = {
  range: PropTypes.object,
  setRange: PropTypes.func,
};

export default SelectMonthRange;
