import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { openDialog } from '../../../../store/dialogs';
import { navigate } from '../../../../store/pages';
import SettingsIcon from './SettingsIcon';

function MobileBreadcrumbs(props) {
  const { links, isOrgEditor } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (link) => {
    dispatch(
      openDialog({
        type: link.type,
        id: link.id,
        mode: 'edit',
      })
    );
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (otherLinks.length === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleLinkClick = (link) => {
    dispatch(navigate({ page: link.type, id: link.id }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  const link = links[links.length - 1];
  const otherLinks = links.slice(0, -1);
  return (
    <Box
      sx={{
        ml: 1,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
      <Typography
        align='left'
        onClick={handleClick}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          maxWidth: '100%',
        }}>
        {link?.name}
      </Typography>
      <SettingsIcon
        isOrgEditor={isOrgEditor}
        link={link}
        handleClick={handleIconClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <List disablePadding>
          {otherLinks.map((link) => (
            <ListItem disablePadding key={link.id}>
              <ListItemButton onClick={() => handleLinkClick(link)}>
                <ListItemText primary={link.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
}

export default MobileBreadcrumbs;
