import React from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import size from 'lodash/size';

import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { openDialog } from '../../../../store/dialogs';
import { navigate } from '../../../../store/pages';
import SettingsIcon from './SettingsIcon';

function DesktopBreadcrumbs(props) {
  const { links, isOrgEditor } = props;
  const dispatch = useDispatch();

  const handleClick = (link) => {
    dispatch(navigate({ page: link.type, id: link.id }));
  };

  const handleButtonClick = (link) => {
    dispatch(openDialog({ type: link.type, id: link.id, mode: 'edit' }));
  };

  return (
    <MuiBreadcrumbs ml={2} sx={{ color: 'white' }}>
      {map(links, (link, idx) => {
        return (
          <Stack
            key={link.id}
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Link
              underline='hover'
              color='inherit'
              onClick={() => handleClick(link)}>
              {link.name}
            </Link>
            {idx + 1 === size(links) && (
              <SettingsIcon
                isOrgEditor={isOrgEditor}
                link={link}
                handleClick={handleButtonClick}
              />
            )}
          </Stack>
        );
      })}
    </MuiBreadcrumbs>
  );
}

export default DesktopBreadcrumbs;
