import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {
  Area,
  Bar,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  addEstimatedProductionToChartData,
  prepareMeterChartData,
} from '../../../../helpers/chart-data';
import ChartTooltip from './Tooltip';
import { navigate } from '../../../../store/pages';

function MeterChart({ site, meter }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [range, setRange] = useState({});
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // set range
  useEffect(() => {
    if (!isEmpty(site)) {
      let start = dayjs
        .utc()
        .startOf('day')
        .subtract(site.timezone.offset, 'hour')
        .subtract(1, 'day')
        .valueOf();
      let end = dayjs
        .utc()
        .endOf('day')
        .subtract(site.timezone.offset, 'hour')
        .valueOf();

      setRange({ start, end });
    }
  }, [site]);

  // set rawData
  useEffect(() => {
    if (!isEmpty(meter)) {
      setRawData({
        meter_id: meter.meter_id,
        records: meter.timeseries,
      });
    }
  }, [meter]);

  // set chartData
  useEffect(() => {
    if (!isEmpty(rawData)) {
      setChartData(
        addEstimatedProductionToChartData(
          prepareMeterChartData(rawData, range),
          [meter],
          site.timezone.offset
        )
      );
    }
  }, [site, meter, range, rawData]);

  const handleClick = () => {
    dispatch(
      navigate({
        page: 'meter',
        id: meter.meter_id,
        tab: 'analysis',
      })
    );
  };

  const height = isMobile ? 150 : 175;
  return (
    <ResponsiveContainer
      key={`chart-${meter.meter_id}`}
      width='100%'
      height={height}
      style={{ marginBottom: 2 }}>
      <ComposedChart
        onClick={handleClick}
        width='100%'
        height={height}
        data={chartData}
        margin={{
          top: 5,
          right: 5,
          left: 0,
          bottom: 0,
        }}>
        <YAxis
          mirror
          domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
          tickFormatter={(val, _axis) => {
            return numeral(val.toPrecision(4)).format('0,0.[000]') + ` kW`;
          }}
        />
        <XAxis
          type='number'
          dataKey='timestamp'
          domain={[range.start / 1000, range.end / 1000]}
          tickFormatter={(unixTime) => {
            return dayjs(unixTime * 1000)
              .utcOffset(get(site, 'timezone.offset'))
              .format('MM/DD h:mm A');
          }}
          hide
        />
        <ReferenceLine y={0} stroke={theme.palette.veregy_colors.lightgrey} />
        <Tooltip
          content={<ChartTooltip timezone={site.timezone} meter={meter} />}
        />
        <Area
          key={`area-${meter.meter_id}`}
          type='monotone'
          dataKey={meter.meter_id}
          fill={theme.palette.veregy_colors.blue}
        />
        <Bar
          key={`bar-${meter.meter_id}`}
          dataKey={`${meter.meter_id}:estimated`}
          fill={theme.palette.veregy_colors.lightgrey}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

MeterChart.propTypes = {
  site: PropTypes.object,
  meter: PropTypes.object,
};

export default MeterChart;
