import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  EditingState,
  FilteringState,
  PagingState,
  SortingState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { createSubscriptions, deleteSubscriptions } from '../../../store/user';
import {
  createTriggerDevices,
  deleteTriggerDevices,
} from '../../../store/alarms';
import useAllAlarmConfig from '../../../store/hooks/useAllAlarmConfig';
import useDeviceNamePredicate from '../../../store/hooks/useDeviceNamePredicate';
import useTriggerNamePredicate from '../../../store/hooks/useTriggerNamePredicate';
import useCompareDeviceName from '../../../store/hooks/useCompareDeviceName';
import useCompareTriggerName from '../../../store/hooks/useCompareTriggerName';
import { openAlarmDialog, openConfirmDialog } from '../../../store/dialogs';

import SelectOrganization from '../../selectors/SelectOrganization';
import SelectDeviceType from '../../charts/selectors/SelectDeviceType';
import BaseTable from './BaseTable';
import {
  AlarmConfigCheckBoxTypeProvider,
  AlarmLevelTypeProvider,
  DeviceTypeProvider,
  SubscribedUsersTypeProvider,
  TriggerTypeProvider,
} from '../providers';
import SelectAll from '../../selectors/SelectAll';
import SubscribedUsersDialog from '../../Dialogs/SubscribedUsersDialog';

function AlarmsConfigurationTable(props) {
  const {
    resource,
    isOrgEditor = false,
    orgSelect = false,
    typeSelect = false,
  } = props;
  const dispatch = useDispatch();

  const deviceNamePredicate = useDeviceNamePredicate();
  const triggerNamePredicate = useTriggerNamePredicate();
  const compareDeviceName = useCompareDeviceName();
  const compareTriggerName = useCompareTriggerName();

  const organizations = useSelector((state) => state.organizations.data);
  const { item: user, memberships } = useSelector((state) => state.user);
  const allDevices = useAllAlarmConfig(resource);

  const [selectedOrganization, setSelectedOrganization] = useState({
    org_id: '',
  });
  const [selectedType, setSelectedType] = useState('');
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [userOrganizations, setUserOrganizations] = useState([]);

  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'device_id', predicate: deviceNamePredicate },
    { columnName: 'trigger_id', predicate: triggerNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'device_id', compare: compareDeviceName },
    { columnName: 'trigger_id', compare: compareTriggerName },
  ]);

  useEffect(() => {
    let _userOrgs = map(memberships, (membership) => {
      return find(organizations, { org_id: membership.org_id });
    });
    setUserOrganizations(_userOrgs);
  }, [user, memberships, organizations]);

  useEffect(() => {
    let _columns = [
      { title: 'Active', name: 'trigger_device_id', width: 0.075 },
      { title: 'Subscribed', name: 'subscription_id', width: 0.1 },
      { title: 'Severity', name: 'level', width: 0.075 },
      { title: 'Device', name: 'device_id', width: 0.35 },
      { title: 'Trigger', name: 'trigger_id', width: 0.25 },
      {
        title: 'Subscribed',
        name: 'subscribed_users',
        width: 0.075,
      },
    ];
    setColumns(_columns);
  }, [user, isOrgEditor]);

  useEffect(() => {
    let _tableData = allDevices;

    if (selectedOrganization?.org_id) {
      _tableData = filter(_tableData, { org_id: selectedOrganization.org_id });
    }
    if (selectedType) {
      _tableData = filter(_tableData, { device_type: selectedType });
    }
    setTableData(_tableData);
  }, [selectedOrganization.org_id, selectedType, allDevices]);

  const handleClick = (attrs) => {
    dispatch(openAlarmDialog(attrs.row));
  };

  const handleActivateAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return !relation.trigger_device_id;
    });

    dispatch(
      openConfirmDialog({
        title: 'Activate All',
        message: `Are you sure you want to activate all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(createTriggerDevices(triggerDevices)),
      })
    );
  };

  const handleDeactivateAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.trigger_device_id;
    });
    dispatch(
      openConfirmDialog({
        title: 'De-activate All',
        message: `Are you sure you want to de-activate all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(deleteTriggerDevices(triggerDevices)),
      })
    );
  };

  const handleSubscribeAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.trigger_device_id && !relation.subscription_id;
    });

    const subscriptions = map(triggerDevices, (triggerDevice) => ({
      userId: user.user_id,
      orgId: triggerDevice.org_id,
      deviceId: triggerDevice.device_id,
      triggerId: triggerDevice.trigger_id,
    }));
    dispatch(
      openConfirmDialog({
        title: 'Subscribe to All',
        message: (
          <>
            <p>
              Are you sure you want to subscribe to all selected alarms (
              {triggerDevices.length})?
            </p>
            <p>Users can only subscribe to active alarms.</p>
          </>
        ),
        onConfirm: () => dispatch(createSubscriptions(subscriptions)),
      })
    );
  };

  const handleUnsubscribeAll = () => {
    let triggerDevices = filter(tableData, (relation) => {
      return relation.subscription_id && relation.trigger_device_id;
    });

    dispatch(
      openConfirmDialog({
        title: 'Unsubscribe from All',
        message: `Are you sure you want to unsubscribe from all selected alarms (${triggerDevices.length})?`,
        onConfirm: () => dispatch(deleteSubscriptions(triggerDevices)),
      })
    );
  };

  const handleSelectOrg = (orgId) => {
    const organization = find(userOrganizations, {
      org_id: orgId,
    });
    if (organization) {
      setSelectedOrganization(organization);
    } else {
      setSelectedOrganization({ org_id: '' });
    }
  };

  const showGrid = orgSelect || typeSelect || tableData.length > 1;
  return (
    <>
      {showGrid && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <Stack
            direction='row'
            spacing={1}
            justifyContent='flex-start'
            alignItems='flex-end'
            sx={{ height: '56px', width: '100%' }}>
            {isOrgEditor && (
              <SelectAll
                label='Active'
                selectLabel='Activate All'
                deselectLabel='Deactivate All'
                attribute='trigger_device_id'
                items={tableData}
                handleSelectAll={handleActivateAll}
                handleDeselectAll={handleDeactivateAll}
              />
            )}
            <SelectAll
              label='Subscribed'
              selectLabel='Subscribe to All'
              deselectLabel='Unsubscribe from All'
              attribute='subscription_id'
              items={tableData}
              handleSelectAll={handleSubscribeAll}
              handleDeselectAll={handleUnsubscribeAll}
            />
            {orgSelect && (
              <Card sx={{ width: '100%', maxWidth: 350 }}>
                <SelectOrganization
                  orgId={selectedOrganization.org_id}
                  setOrgId={handleSelectOrg}
                  organizations={userOrganizations}
                />
              </Card>
            )}
            {typeSelect && (
              <SelectDeviceType
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />
            )}
          </Stack>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <Card raised sx={{ maxWidth: '96vw', p: 1, pb: 0.5 }}>
          <BaseTable
            minWidth={900}
            maxWidth={1600}
            rows={tableData}
            columns={columns}
            editColumn={<TableEditColumn width={5} />}>
            <DeviceTypeProvider for={['device_id']} />
            <TriggerTypeProvider for={['trigger_id']} />
            <AlarmConfigCheckBoxTypeProvider
              for={['trigger_device_id', 'subscription_id']}
              handleClick={handleClick}
            />
            <AlarmLevelTypeProvider for={['level']} />
            <SubscribedUsersTypeProvider for={['subscribed_users']} />

            <EditingState />
            <FilteringState />
            <SortingState
              defaultSorting={[{ columnName: 'level', direction: 'desc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <IntegratedPaging />
          </BaseTable>
        </Card>
      </Grid>
      <SubscribedUsersDialog />
    </>
  );
}

AlarmsConfigurationTable.propTypes = {
  resource: PropTypes.object,
  orgSelect: PropTypes.bool,
  typeSelect: PropTypes.bool,
};

export default AlarmsConfigurationTable;
