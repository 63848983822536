import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';

import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';

import useOrgNamePredicate from '../../../../store/hooks/useOrgNamePredicate';
import useCompareOrgName from '../../../../store/hooks/useCompareOrgName';
import {
  DateTypeProvider,
  OrgIdNameLinkTypeProvider,
  SelectTypeProvider,
} from '../../../../components/table/providers';

import BaseTable from '../../../../components/table/tables/BaseTable';

function LicensesTable(props) {
  const { orgId } = props;
  const allLicenses = useSelector((state) => state.licenses.data);

  const orgNamePredicate = useOrgNamePredicate();
  const compareOrgName = useCompareOrgName();

  const [tableColumnExtensions] = useState([
    { columnName: 'active', align: 'center' },
  ]);
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);
  const [licenses, setLicenses] = useState([]);

  const [columns] = useState([
    { title: 'Status', name: 'active', width: 0.1 },
    { title: 'Organization', name: 'org_id', width: 0.5 },
    { title: 'License', name: 'name', width: 0.15 },
    { title: 'End Date', name: 'end_date', width: 0.2 },
  ]);

  useEffect(() => {
    let _licenses = allLicenses;
    if (orgId) {
      _licenses = filter(_licenses, { org_id: orgId });
    }
    if (_licenses) {
      setLicenses(_licenses);
    }
  }, [allLicenses, orgId]);

  return (
    <Card raised sx={{ maxWidth: '96vw', width: '100%', px: 1, pb: 0.5 }}>
      <BaseTable
        minWidth={550}
        maxWidth={800}
        rows={licenses}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}>
        <OrgIdNameLinkTypeProvider for={['org_id']} />
        <SelectTypeProvider key='active' for={['active']} />
        <DateTypeProvider for={['end_date']} />

        <EditingState />
        <FilteringState />
        <SortingState
          defaultSorting={[{ columnName: 'org_id', direction: 'asc' }]}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedFiltering
          columnExtensions={integratedFilteringColumnExtensions}
        />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />
        <IntegratedPaging />
      </BaseTable>
    </Card>
  );
}

export default LicensesTable;
